<template>
  <div>
    <AppMenu />
    <TestimonialPatologySection
      subject="Andrea Cappelletti"
      patology="myreview"
      :image="localImage1"
      testimonial=" Sono un ragazzo di 21 anni, sin dall'adolescenza soffrivo di reflusso gastroesofageo..."
    />
    <CtaSection />
    <FooterSection />
  </div>
</template>

<script>
import AppMenu from "../components/AppMenu";
import FooterSection from "../components/FooterSection.vue";
import CtaSection from "../components/CtaSection.vue";
import TestimonialPatologySection from "../components/TestimonialPatologySection.vue";
import testimonialImage1 from "@/assets/testimonial/AndreaRiccardo.jpg";

export default {
  name: "Team",
  components: {
    AppMenu,
    CtaSection,
    FooterSection,
    TestimonialPatologySection,
  },
  data() {
    return {
      localImage1: testimonialImage1,
    };
  },
};
</script>
